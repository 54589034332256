
import {
  getAuth,
  EmailAuthProvider,
  GoogleAuthProvider,
} from 'firebase/auth';

import uiMixin from '../mixins/ui';

let FBUIAuth;

export default {
  name: 'AuthView',
  _authUI: undefined,
  mixins: [
    uiMixin,
  ],
  data() {
    return {
      didInitAuth: false,
      signInEmailAddress: '',
      signInPassword: '',
    };
  },
  computed: {
    isOnline() {
      return this.$nuxt.isOnline;
    },
  },
  watch: {
    isOnline() {
      if (this.isOnline && !this.didInitAuth && !this._authUI) {
        this.$nextTick(() => {
          this.initAuth();
        });
      } else if (!this.isOnline) {
        this.destroyAuth();
      }
    },
  },
  mounted() {
    this.$bindKeys.bind('auth', {}, true);

    if (!this.isNative && this.isOnline) {
      this.initAuth();
    }

    this.$store.dispatch('logEvent', { name: 'auth_show' });
  },
  destroyed() {
    this.$bindKeys.unbind('auth');
    this.destroyAuth();
  },
  methods: {
    handleSignInWithApple() {
      if (this.isNative) {
        this.$auth.signInWithApple();
      }
    },
    handleSignInWithGoogle() {
      if (this.isNative) {
        this.$auth.signInWithGoogle();
      }
    },
    async initAuth() {
      if (!FBUIAuth) {
        const FBUI = await import('firebaseui');
        FBUIAuth = FBUI.auth;
      }

      if (this.didInitAuth || this._authUI || !FBUIAuth) {
        return;
      }

      this.didInitAuth = true;
      this._authUI = new FBUIAuth.AuthUI(getAuth());
      this._authUI.disableAutoSignIn();
      const signInOptions = [
        'apple.com',
        GoogleAuthProvider.PROVIDER_ID,
      ];

      if (this.$nuxt.$config.public.isFirebaseDev) {
        signInOptions.push(EmailAuthProvider.PROVIDER_ID);
      }

      this._authUI.start('#firebaseui-auth-container', {
        signInOptions,
        tosUrl: `${this.$store.state.app.url}/terms`,
        privacyPolicyUrl: `${this.$store.state.app.url}/terms`,
        callbacks: {
          signInSuccessWithAuthResult: (/* authResult, redirectUrl */) => {
            return false;
          },
        },
      });
    },
    destroyAuth() {
      if (this._authUI) {
        this._authUI.delete();
        this._authUI = undefined;
        this.didInitAuth = false;
      }
    },
  },
};
